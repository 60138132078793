import React from 'react'
import Layout from "../components/App/Layout"
import NavbarThree from "../components/App/NavbarThree"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import { Link } from 'gatsby'
import termsImg from '../assets//images/terms-of-service.jpg'

const TermsOfService = () => {
    return (
        <Layout>
            <NavbarThree />
            <PageBanner
                pageTitle="Terms &amp; Conditions"
                homePageText="Home"
                homePageUrl="/"
                activePageText="Terms &amp; Conditions"
            />
            <section className="terms-of-service-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="terms-of-service-content">



                                <p><span><strong>Agreement between user and Techna.</strong></span></p>
                                <p><span>The https://techna.pk website (the “Site”) is comprised of various web pages operated by Techna, it is offered to you conditioned on your acceptance without modification of the terms, conditions, and notices contained herein (the “Terms”). Your use of https://techna.pk constitutes your agreement to all such Terms. Please read these terms carefully, and keep a copy of them for your reference.</span></p>
                                <p><span>Techna provides online and offline training that offers online entrepreneurship education courses</span></p>

                                <p><span><strong>Electronic Communications</strong></span></p>
                                <p><span>Visiting https://techna.pk or sending emails to Techna constitutes electronic communications. You consent to receive electronic communications and you agree that all agreements, notices, disclosures and other communications that we provide to you electronically, via email and on the Site, satisfy any legal requirement that such communications be in writing.</span></p>

                                <p><span><strong>Your Account</strong></span></p>
                                <p><span>If you use this site, you are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer, and you agree to accept responsibility for all activities that occur under your account or password. You may not assign or otherwise transfer your account to any other person or entity. You acknowledge that Techna is not responsible for third party access to your account that results from theft or misappropriation of your account. Techna and its associates reserve the right to refuse or cancel service, terminate accounts, or remove or edit content in our sole discretion.</span></p>
                                <p><span>Techna does not knowingly collect, either online or offline, personal information from persons under the age of thirteen. If you are under 18, you may use https://techna.pk only with permission of a parent or guardian.</span></p>

                                <p><span><strong>Refund/Cancellation Policy</strong></span></p>
                                <p><span>Techna do not offer any refund policy.</span></p>

                                <p><span><strong>Links to Third Party Sites/Third Party Services</strong></span></p>
                                <p><span>https://techna.pk may contain links to other websites (“Linked Sites”). The Linked Sites are not under the control of Techna and is not responsible for the contents of any Linked Site, including without limitation any link contained in a Linked Site, or any changes or updates to a Linked Site Techna is providing these links to you only as a convenience, and the inclusion of any link does not imply endorsement by Techna of the site or any association with its operators.</span></p>
                                <p><span>Certain services made available via https://techna.pk are delivered by third party sites and organizations. By using any product, service or functionality originating from the https://techna.pk domain, you hereby acknowledge and consent that Techna may share such information and data with any third party with whom&nbsp;Techna has a contractual relationship to provide the requested product, service or functionality on behalf of https://techna.pk users and customers.</span></p>
                                <p><span>All content included as part of the Service, such as text, graphics, logos, images, as well as the compilation thereof, and any software used on the Site, is the property of Techna or its suppliers and protected by copyright and other laws that protect intellectual property and proprietary rights. You agree to observe and abide by all copyright and other proprietary notices, legends or other restrictions contained in any such content and will not make any changes thereto.</span></p>
                                <p><span>You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create derivative works, or in any way exploit any of the content, in whole or in part, found on the Site. Techna content is not for sale or resale. You are purchasing licensing access to use the site to access the content ONLY, you are not purchasing any ownership to the content itself, and therefore have no rights to the content. Your use of the Site does not entitle you to make any unauthorized use of any protected content, and in particular you will not delete or alter any proprietary rights or attribution notices in any content. You will use protected content solely for your personal use, and will make no other use of the content without the express written permission of Techna and the copyright owner. You agree that you do not acquire any ownership rights in any protected content. We do not grant you any licenses, express or implied, to the intellectual property of Techna or our licensors except as expressly authorized by these Terms.</span></p>

                                <p><span><strong>Use of Communication Services</strong></span></p>
                                <p><span>The Site may contain bulletin board services, chat areas, news groups, forums, communities, personal web pages, calendars, and/or other message or communication facilities designed to enable you to communicate with the public at large or with a group (collectively, “Communication Services”), you agree to use the Communication Services only to post, send and receive messages and material that are proper and related to the particular Communication Service.</span></p>
                                <p><span>By way of example, and not as a limitation, you agree that when using a Communication Service, you will not: defame, abuse, harass, stalk, threaten or otherwise violate the legal rights (such as rights of privacy and publicity) of others; publish, post, upload, distribute or disseminate any inappropriate, profane, defamatory, infringing, obscene, indecent or unlawful topic, name, material or information; upload files that contain software or other material protected by intellectual property laws (or by rights of privacy of publicity) unless you own or control the rights thereto or have received all necessary consents; upload files that contain viruses, corrupted files, or any other similar software or programs that may damage the operation of another’s computer; advertise or offer to sell or buy any goods or services for any business purpose, unless such Communication Service specifically allows such messages; conduct or forward surveys, contests, pyramid schemes or chain letters; download any file posted by another user of a Communication Service that you know, or reasonably should know, cannot be legally distributed in such manner; falsify or delete any author attributions, legal or other proper notices or proprietary designations or labels of the origin or source of software or other material contained in a file that is uploaded, restrict or inhibit any other user from using and enjoying the Communication Services; violate any code of conduct or other guidelines which may be applicable for any particular Communication Service; harvest or otherwise collect information about others, including e-mail addresses, without their consent; violate any applicable laws or regulations.</span></p>
                                <p><span>Techna has no obligation to monitor the Communication Services. However, Techna reserves the right to review materials posted to a Communication Service and to remove any materials in its sole discretion. Techna reserves the right to terminate your access to any or all of the Communication Services at any time without notice for any reason whatsoever.</span></p><p><span>Techna reserves the right at all times to disclose any information as necessary to satisfy any applicable law, regulation, legal process or governmental request, or to edit, refuse to post or to remove any information or materials, in whole or in part, in Techna sole discretion.</span></p>
                                <p><span>Always use caution when giving out any personally identifying information about yourself or your children in any Communication Service. Techna does not control or endorse the content, messages or information found in any Communication Service and, therefore, Techna specifically disclaims any liability with regard to the Communication Services and any actions resulting from your participation in any Communication Service. Managers and hosts are not authorized Techna&nbsp; spokespersons, and their views do not necessarily reflect those of Techna</span></p>
                                <p><span>Materials uploaded to a Communication Service may be subject to posted limitations on usage, reproduction and/or dissemination. You are responsible for adhering to such limitations if you upload the materials.</span></p>

                                <p><span><strong>Modifications</strong></span></p>
                                <p><span>Techna may revise these terms of service for its website at any time without notice. By using this website you are agreeing to be bound by the then current version of these terms of service.</span></p>


                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <aside className="widget-area">
                                <div className="widget widget_insight">
                                    <ul>
                                        <li>
                                            <Link to="/about-us">
                                                About Us
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/contact">
                                                Contact Us
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/privacy-policy">
                                                Privacy Policy
                                            </Link>
                                        </li>
                                        <li className="active">
                                            <Link to="#">
                                                Terms &amp; Conditions
                                            </Link>
                                        </li>
                                    </ul>
                                </div>

                                {/* <div className="widget widget_recent_courses">
                                    <h3 className="widget-title">Recent Courses</h3>

                                    <article className="item">
                                        <Link to="#" className="thumb">
                                            <span className="fullimage cover bg1" role="img"></span>
                                        </Link>
                                        <div className="info">
                                            <span>$49.00</span>
                                            <h4 className="title usmall"><Link to="#">The Data Science Course 2020: Complete Data Science Bootcamp</Link></h4>
                                        </div>
                                        <div className="clear"></div>
                                    </article>

                                    <article className="item">
                                        <Link to="#" className="thumb">
                                            <span className="fullimage cover bg2" role="img"></span>
                                        </Link>
                                        <div className="info">
                                            <span>$59.00</span>
                                            <h4 className="title usmall"><Link to="#">Java Programming MasterclassName for Software Developers</Link></h4>
                                        </div>
                                        <div className="clear"></div>
                                    </article>

                                    <article className="item">
                                        <Link to="#" className="thumb">
                                            <span className="fullimage cover bg3" role="img"></span>
                                        </Link>
                                        <div className="info">
                                            <span>$69.00</span>
                                            <h4 className="title usmall"><Link to="#">Deep Learning A-Z™: Hands-On Artificial Neural Networks</Link></h4>
                                        </div>
                                        <div className="clear"></div>
                                    </article>
                                </div> */}

                                {/* <div className="widget widget_tag_cloud">
                                    <h3 className="widget-title">Popular Tags</h3>

                                    <div className="tagcloud">
                                    <Link to="#">Business <span className="tag-link-count"> (3)</span></Link>
                                    <Link to="#">Design <span className="tag-link-count"> (3)</span></Link>
                                    <Link to="#">Braike <span className="tag-link-count"> (2)</span></Link>
                                    <Link to="#">Fashion <span className="tag-link-count"> (2)</span></Link>
                                    <Link to="#">Travel <span className="tag-link-count"> (1)</span></Link>
                                    <Link to="#">Smart <span className="tag-link-count"> (1)</span></Link>
                                    <Link to="#">Marketing <span className="tag-link-count"> (1)</span></Link>
                                    <Link to="#">Tips <span className="tag-link-count"> (2)</span></Link>
                                    </div>
                                </div> */}
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </Layout>
    )
}

export default TermsOfService;